// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-layout-layout-post-component-tsx": () => import("./../../../src/components/layout/layoutPost.component.tsx" /* webpackChunkName: "component---src-components-layout-layout-post-component-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-nasza-firma-tsx": () => import("./../../../src/pages/nasza-firma.tsx" /* webpackChunkName: "component---src-pages-nasza-firma-tsx" */),
  "component---src-pages-polityka-prywatnosci-tsx": () => import("./../../../src/pages/polityka-prywatnosci.tsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-tsx" */),
  "component---src-pages-realizacje-tsx": () => import("./../../../src/pages/realizacje.tsx" /* webpackChunkName: "component---src-pages-realizacje-tsx" */)
}

