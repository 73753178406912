export const colors = {
  black: "#000",
  black_2: "#2c2738",
  black_3: "#14142b",
  lighterBlack: "#242424",
  grey: "#fafafa",
  darkerGrey: "#e5e5e5",
  orange: "#fcc615",
  darkerOrange: "#ecb603",
  white: "#fcfcfc",
  yellow: "#fcc615",
  mint: "#eff6f0",
  silver: "#d1d1d1",
  brightYellow: "#fffde2",
  blue: "#edf2f9",
  popupBg: "#0000007e",
  success: "#75B81F",
  error: "#AA0D22",
};
