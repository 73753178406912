export const fontSizes = {
  xxsmall: '1.2rem',
  xsmall: '1.4rem',
  small: '1.6rem',
  medium: '2rem',
  xmedium: '2.8rem',
  large: '3.2rem',
  xlarge: '4.8rem',
  xxlarge: '6.4rem',
};
